import React, { useEffect, useState } from 'react';
import '../App.css';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';
import { AccordionSummary, Accordion, Container, Stack, TextField } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { on } from 'events';

type Question = {
    id: number;
    question: string;
    answer: string;
}

type GameProps = {
    id?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    questions?: Question[];
    uniqueQuestions?: Question[];
    date?: string;
    onDelete?: (id: string) => void;
    onSave?: () => void;
}

const GameComponent: React.FC<GameProps> = (props) => {
    const [id, _] = useState(props.id ? props.id : uuidv4());
    const [firstName, setFirstName] = useState(props.firstName ? props.firstName : '');
    const [middleName, setMiddleName] = useState(props.middleName ? props.middleName : '');
    const [lastName, setLastName] = useState(props.lastName ? props.lastName : '');
    const [questions, setQuestions] = useState(props.questions ? props.questions : generateDefaultQuestions());
    const [uniqueQuestions, setUniqueQuestions] = useState(props.uniqueQuestions ? props.uniqueQuestions : generateDefaultQuestionsUnique());
    const [date, setDate] = useState(props.date ? props.date : ''); 
    const onSave = props.onSave ? props.onSave : () => {return;};

    // #region Functions
    const setQuestion = (value: string, index: number) => {
        const question = {...questions[index]};
        question.question = value;
        questions[index] = question;
        setQuestions(questions);
    }

    const setAnswer = (value: string, index: number) => {
        const question = {...questions[index]};
        question.answer = value;
        questions[index] = question;
        setQuestions(questions);
    }

    const deleteQuestion = (index: number) => {
        const newQuestions = questions.filter((_, i) => i !== index);
        setQuestions(newQuestions);
    };

    const setUniqueQuestion = (value: string, index: number) => {
        const question = {...uniqueQuestions[index]};
        question.question = value;
        uniqueQuestions[index] = question;
        setUniqueQuestions(uniqueQuestions);
    }

    const setUniqueAnswer = (value: string, index: number) => {
        const question = {...uniqueQuestions[index]};
        question.answer = value;
        uniqueQuestions[index] = question;
        setUniqueQuestions(uniqueQuestions);
    }

    const deleteUniqueQuestion = (index: number) => {
        const newQuestions = uniqueQuestions.filter((_, i) => i !== index);
        setUniqueQuestions(newQuestions);
    }

    const saveGame = () => {
        if (!date) {
            console.log('Date is required');
            return;
        }

        if (questions.filter(q => q.question === '').length > 0 || questions.filter(q => q.answer === '').length > 0) {
            console.log('All standard questions must have a question and answer');
            return;
        }

        if (uniqueQuestions.filter(q => q.question === '').length > 0 || uniqueQuestions.filter(q => q.answer === '').length > 0) {
            console.log('All unique questions must have a question and answer');
            return;
        }
        
        const gameQuestions = { standard: questions, unique: uniqueQuestions };
        const game = {id, firstName, middleName, lastName, questions: gameQuestions, date};
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(game)
        };
        fetch('https://api.admin.famousinitials.com/games', requestOptions)
        .then(async response => {
            const data = await response.json();
            console.log(data);

            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            onSave();
        })
        .catch(error => {
            console.error('There was an error!', error);
        })
    };

    const deleteGame = () => {
        props.onDelete && props.onDelete(id);
    };

    // #endregion


    return (
        <Container>
            <Stack direction="row" spacing={2}>
                <TextField id="firstName" label="First Name" defaultValue={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                <TextField id="middleName" label="Middle Name" defaultValue={middleName} onChange={(e) => { setMiddleName(e.target.value) }} />
                <TextField id="lastName" label="Last Name" defaultValue={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                <TextField id="date" type='date' defaultValue={date} onChange={(e) => { setDate(e.target.value) }} />
                <Button variant="contained" onClick={() => { saveGame()}}>Save</Button>
                <Button variant="contained" onClick={() => { deleteGame()}}>Delete</Button>
            </Stack>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDown />}>
                        Standard Questions
                </AccordionSummary>
                <Stack spacing={1}>
                    {questions.map((question, index) => {
                        return (
                            <Stack key={question.id} spacing={2} direction="row" width={"100%"}>
                                <TextField id={"question-"+question.id} disabled label="Question" variant="standard" defaultValue={question.question} fullWidth/>
                                <TextField id={"answer-"+question.id} label="Answer" variant="standard" defaultValue={question.answer} onChange={(e) => setAnswer(e.target.value, index)} fullWidth/>
                            </Stack>
                        );
                    })}
                </Stack>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDown />}>
                        Unique Questions
                </AccordionSummary>
                <Stack spacing={1}>
                    {uniqueQuestions.map((question, index) => {
                        return (
                            <Stack key={question.id} spacing={2} direction="row" width={"100%"}>
                                <TextField id={"question-"+question.id} label="Question" variant="standard" defaultValue={question.question} onChange={(e) => setUniqueQuestion(e.target.value, index)} fullWidth/>
                                <TextField id={"answer-"+question.id} label="Answer" variant="standard" defaultValue={question.answer} onChange={(e) => setUniqueAnswer(e.target.value, index)} fullWidth/>
                                <Button variant="contained" onClick={() => deleteUniqueQuestion(index)}>Delete</Button>
                            </Stack>
                        );
                    })}
                <Button variant="contained" onClick={() => setUniqueQuestions([...uniqueQuestions, { id: Math.max(...uniqueQuestions.map(o => o.id)) + 1, question: '', answer: '' }])}>Add Unique Question</Button>
                </Stack>
            </Accordion>
        </Container>
    );
}

export default GameComponent;

function generateDefaultQuestions(): Question[] {
    const questions = [] as Question[];
    questions.push({ id: 0, question: 'What are they famous for?', answer: '' });
    questions.push({ id: 1, question: 'Are they a child, adult, senior, or deceased?', answer: '' });
    questions.push({ id: 2, question: 'What is their gender?', answer: '' });
    questions.push({ id: 3, question: 'What is their nationality?', answer: '' });
    questions.push({ id: 4, question: 'Are they a parent?', answer: '' });
    questions.push({ id: 5, question: 'What is their relationship status?', answer: '' });
    return questions;
}

function generateDefaultQuestionsUnique(): Question[] {
    const questions = [] as Question[];
    questions.push({ id: 0, question: 'Specific question 1', answer: '' });
    return questions;
}

