import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import configureAmplify from '../configuration/amplify';
Amplify.configure(configureAmplify());

const Home: React.FC = () => {
    return <div className="App">
        <Authenticator socialProviders={['google']}>
            {({ signOut, user }) => (
                <main>
                <h1>Hello {user?.username ?? 'Guest'}</h1>
                <button onClick={signOut}>Sign out</button>
                </main>
            )}
        </Authenticator>
        <Button component={Link} to="/managegames">Manage Games</Button>
        <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
            Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
        >
            Learn React
        </a>
        </header>
    </div>
};

export default Home;