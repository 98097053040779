import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import './App.css';
import ManageGames from './pages/ManageGames';
import { Authenticator } from '@aws-amplify/ui-react';

const App: React.FC = () => (
  <Authenticator socialProviders={['google']}>
    {({ signOut, user }) => (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/managegames" element={<ManageGames />} />
        </Routes>
      </Router>
    )}
  </Authenticator>
);

export default App;
