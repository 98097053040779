import React, { useEffect, useState } from 'react';
import '../App.css';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import GameComponent from '../components/GameComponent';
import { Container, CssBaseline, Divider, Grid } from '@mui/material';
import configureAmplify from '../configuration/amplify';
import { Amplify } from 'aws-amplify';
Amplify.configure(configureAmplify());

type Game = {
    gameid?: string;
    firstname?: string;
    middlename?: string;
    lastname?: string;
    questions?: Question[];
    gamedate: string;
}

type Question = {
    id: number;
    question: string;
    answer: string;
}

const ManageGames: React.FC = () => {
    const [gameList, setGameList] = useState([] as Game[]) // TODO: Add type for gameList

    const loadGames = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch('https://api.admin.famousinitials.com/games', requestOptions)
        .then(async response => {
            const data = await response.json();
            console.log(data);
            
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            data.sort((a: Game, b: Game) => {
                if(!a.gamedate) {
                    return 1;
                }
                if(!b.gamedate) {
                    return -1;
                }
                return new Date(a.gamedate).getTime() - new Date(b.gamedate).getTime()})
            sortGames();
            setGameList(data);
        })
        .catch(error => {
            console.error('There was an error!', error);
        })
    };

    const sortGames = () => {
        gameList.sort((a: Game, b: Game) => {
                if(!a.gamedate) {
                    return 1;
                }
                if(!b.gamedate) {
                    return -1;
                }
                return new Date(a.gamedate).getTime() - new Date(b.gamedate).getTime()})
        setGameList(gameList);
    }

    const deleteGame = (id: string) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(`https://api.admin.famousinitials.com/games/${id}`, requestOptions)
        .then(async response => {
            const data = await response.json();
            console.log(data);
            
            // check for error response
            if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }

            setGameList(gameList.filter(game => game.gameid !== id));
        })
        .catch(error => {
            console.error('There was an error!', error);
        })
    };

    useEffect(() => {
        loadGames();
    }, []);
    
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth='xl'>
                <Grid container spacing={3} alignItems='center' justifyItems='center'>    
                    <Grid item xs={8}>         
                        <h1>Manage Games</h1>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" onClick={() => { setGameList([createNewGame(), ...gameList]) }}>Create New Game</Button>
                    </Grid>
                        {gameList.map((game) => {
                            return (
                                <Grid key={game.gameid} item xs={12}>                         
                                    <GameComponent id={game.gameid} firstName={game.firstname} middleName={game.middlename} lastName={game.lastname} questions={game.questions} date={game.gamedate} onDelete={deleteGame} onSave={sortGames} />
                                </Grid>
                            );
                    })}
                </Grid>
            </Container>
        </React.Fragment>
    );
}

function createNewGame(): Game {
    return { gameid: uuidv4(), firstname: '', middlename: '', lastname: '', gamedate: ''};
}

export default ManageGames;
