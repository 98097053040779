import { Amplify } from 'aws-amplify';

function configureAmplify() {
    Amplify.configure({
    Auth: {
        Cognito: {
        //  Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_Fe5YdoxLj',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: '17fkd5k0kleejg11vffsa1u77u',
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-2:da8dc2d5-276c-4c28-839a-dcd90f9ed1bf',
        // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
        allowGuestAccess: true,
        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: 'code', // 'code' | 'link'
        loginWith: {
            // OPTIONAL - Hosted UI configuration
            oauth: {
            domain: 'famousinitials-admin-pool.auth.us-east-2.amazoncognito.com',
            scopes: [
                'email',
                'profile',
                'openid',
                'aws.cognito.signin.user.admin'
            ],
            redirectSignIn: ['https://admin.famousinitials.com/','http://localhost:3000/'],
            redirectSignOut: ['https://admin.famousinitials.com/','http://localhost:3000/'],
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
            }
        }
        }
    }
    });

    // You can get the current config object
    return Amplify.getConfig();
}

export default configureAmplify;